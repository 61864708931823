<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12" class="ihale-list-top">
                  <h4 class="text-white d-inline-block card-title">İhale Listesi</h4>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-primary bg-lighten-2 border-0"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                      to="/ihaleOlustur"
                  >
                    İhale Oluştur
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <b-tabs v-model="currentTab" class="mt-4">
              <!--              <b-tab active title="Tüm İhaleler">-->
              <!--                <hr/>-->
              <!--                <div class="d-flex justify-content-between flex-wrap mt-1">-->
              <!--                  <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">-->
              <!--                    <b-input-group-->
              <!--                      size="sm"-->
              <!--                      prepend="Gösterilecek kayıt sayısı"-->
              <!--                      class="unPrint"-->
              <!--                    >-->
              <!--                      <b-form-select-->
              <!--                        id="per-page-select"-->
              <!--                        v-model="firstPerPage"-->
              <!--                        :options="pageOptions"-->
              <!--                        @change="firstPerPageChange($event)"-->
              <!--                        size="sm"-->
              <!--                      >-->
              <!--                      </b-form-select>-->
              <!--                    </b-input-group>-->
              <!--                  </b-form-group>-->
              <!--                  <b-input-group size="sm" class="search-bar">-->
              <!--                    <b-input-group-prepend>-->
              <!--                      <b-button-->
              <!--                        variant="outline-primary"-->
              <!--                        size="sm"-->
              <!--                        disabled-->
              <!--                        class="search-input mb-0"-->
              <!--                      >-->
              <!--                        Ara-->
              <!--                      </b-button>-->
              <!--                    </b-input-group-prepend>-->
              <!--                    <b-form-input id="filterInput" v-model="firstFilter" type="search"/>-->
              <!--                  </b-input-group>-->
              <!--                </div>-->
              <!--                <hr/>-->
              <!--                <b-table-->
              <!--                  striped-->
              <!--                  hover-->
              <!--                  responsive-->
              <!--                  class="position-relative mt-1 ihale-table"-->
              <!--                  :per-page="firstPerPage"-->
              <!--                  :items="ihaleListesi"-->
              <!--                  :fields="fields"-->
              <!--                  :sort-by.sync="sortBy"-->
              <!--                  :sort-desc.sync="sortDesc"-->
              <!--                  :sort-direction="sortDirection"-->
              <!--                  :firstFilter-included-fields="filterOn"-->
              <!--                  @filtered="onFiltered"-->
              <!--                  @row-clicked="go"-->
              <!--                >-->
              <!--                  <template #cell(ihaleID)="data">-->
              <!--                    {{ data.item.ihaleID }}-->
              <!--                  </template>-->
              <!--                  <template #cell(ihaleDurum)="data">-->
              <!--                    <b-badge :variant="data.item.ihaleDurum == 1 ? 'primary':'warning'">-->
              <!--                      <font-awesome-icon v-if="data.item.ihaleDurum == 1" icon="fa-solid fa-check-to-slot"-->
              <!--                                         class="check-to-slot"-->
              <!--                      />-->
              <!--                      <font-awesome-icon v-else icon="fa-solid fa-hourglass-half"/>-->
              <!--                      {{ data.item.ihaleDurum == 1 ? 'Kapalı' : 'Açık' }}-->
              <!--                    </b-badge>-->
              <!--                  </template>-->
              <!--                  <template #cell(ihaleBaslik)="data">-->
              <!--                    <strong>-->
              <!--                      {{ data.item.ihaleBaslik }}-->
              <!--                    </strong>-->
              <!--                  </template>-->
              <!--                  <template #cell(belgeTarihSayi)="data">-->
              <!--                    <p>{{ data.item.belgeTarihSayi }}</p>-->
              <!--                  </template>-->
              <!--                  <template #cell(belgeTarih)="data">-->
              <!--                    <p>{{ data.item.belgeTarih }}</p>-->
              <!--                  </template>-->
              <!--                  <template #cell(kayitTarih)="data">-->
              <!--                    <b-button-->
              <!--                      @click="ihaleRemove(data.item.ihaleID)"-->
              <!--                      class="btn-icon rounded-circle trash-icon pull-up mr-50"-->
              <!--                      size="sm"-->
              <!--                      v-if="$store.getters.getRole == 1"-->
              <!--                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"-->
              <!--                    >-->
              <!--                      <font-awesome-icon icon="fa-solid fa-trash-can"/>-->
              <!--                    </b-button>-->
              <!--                  </template>-->
              <!--                </b-table>-->
              <!--                <hr/>-->
              <!--                <b-row class="d-flex align-items-center mt-1">-->
              <!--                  <b-col cols="6">-->
              <!--                    <p class="text-muted">Toplam {{ firstTotalRows }} Kayıt bulundu</p>-->
              <!--                  </b-col>-->
              <!--                  <b-col cols="6">-->
              <!--                    <b-pagination-->
              <!--                      v-model="firstCurrentPage"-->
              <!--                      @change="firstCurrentPageChange($event)"-->
              <!--                      :total-rows="firstTotalRows"-->
              <!--                      :per-page="firstPerPage"-->
              <!--                      first-number-->
              <!--                      last-number-->
              <!--                      prev-class="prev-item"-->
              <!--                      next-class="next-item"-->
              <!--                      class="mb-0 float-right"-->
              <!--                    />-->
              <!--                  </b-col>-->
              <!--                </b-row>-->
              <!--              </b-tab>-->
              <b-tab
                  title="Açık İhaleler"
              >
                <hr/>
                <b-row>
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group class="mr-1 mb-md-0 unPrint per-page-input" size="sm"
                                  :style="{width : width < 768 ? '100%': width < 900 ? '85%': width < 1100 ? '65%' : width < 1400 ? '55%': width < 1600 ? '40%':''}"
                    >
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="secondPerPage"
                            :options="pageOptions"
                            @change="secondPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-input-group size="sm" class="search-bar">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="secondFilter" type="search" autocomplete="off"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative mt-1 ihale-table"
                    :per-page="secondPerPage"
                    :items="acikIhaleler"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :firstFilter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="go"
                >
                  <template #cell(ihaleID)="data">
                    {{ data.item.ihaleID }}
                  </template>
                  <template #cell(ihaleDurum)="data">
                    <b-badge :variant="data.item.ihaleDurum == 1 ? 'primary':'warning'">
                      <font-awesome-icon icon="fa-solid fa-hourglass-half"/>
                      {{ data.item.ihaleDurum == 1 ? 'Kapalı' : 'Açık' }}
                    </b-badge>
                  </template>
                  <template #cell(ihaleBaslik)="data">
                    <strong>
                      {{ data.item.ihaleBaslik }}
                    </strong>
                  </template>
                  <template #cell(belgeTarihSayi)="data">
                    <p>{{ data.item.belgeTarihSayi }}</p>
                  </template>
                  <template #cell(belgeTarih)="data">
                    <p>{{ data.item.belgeTarih }}</p>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <b-button
                        @click="ihaleRemove(data.item.ihaleID)"
                        class="btn-icon rounded-circle trash-icon pull-up mr-50"
                        size="sm"
                        v-if="$store.getters.getRole == 1"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ secondTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="secondCurrentPage"
                        @change="secondCurrentPageChange($event)"
                        :total-rows="secondTotalRows"
                        :per-page="secondPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                  title="Kapalı İhaleler"
                  @click="kapaliIhale"
              >
                <hr/>
                <b-row>
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-form-group class="mr-1 mb-md-0 unPrint per-page-input" size="sm"
                                  :style="{width : width < 768 ? '100%': width < 900 ? '85%': width < 1100 ? '65%' : width < 1400 ? '55%': width < 1600 ? '40%':''}"
                    >
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="thirdPerPage"
                            :options="pageOptions"
                            @change="thirdPerPageChange($event)"
                            size="sm"
                        >
                        </b-form-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="6" cols="12">
                    <b-input-group size="sm" class="search-bar">
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="search-input mb-0"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input id="filterInput" v-model="thirdFilter" type="search" autocomplete="off"/>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr/>
                <b-table
                    striped
                    hover
                    responsive
                    class="position-relative mt-1 ihale-table"
                    :per-page="thirdPerPage"
                    :items="kapaliIhaleler"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :firstFilter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="go"
                >
                  <template #cell(ihaleID)="data">
                    {{ data.item.ihaleID }}
                  </template>
                  <template #cell(ihaleDurum)="data">
                    <b-badge :variant="data.item.ihaleDurum == 1 ? 'primary':'warning'">
                      <font-awesome-icon icon="fa-solid fa-check-to-slot" class="check-to-slot"/>
                      {{ data.item.ihaleDurum == 1 ? 'Kapalı' : 'Açık' }}
                    </b-badge>
                  </template>
                  <template #cell(ihaleBaslik)="data">
                    <strong>
                      {{ data.item.ihaleBaslik }}
                    </strong>
                  </template>
                  <template #cell(belgeTarihSayi)="data">
                    <p>{{ data.item.belgeTarihSayi }}</p>
                  </template>
                  <template #cell(belgeTarih)="data">
                    <p>{{ data.item.belgeTarih }}</p>
                  </template>
                  <template #cell(kayitTarih)="data">
                    <b-button
                        @click="ihaleRemove(data.item.ihaleID)"
                        class="btn-icon rounded-circle trash-icon pull-up mr-50"
                        size="sm"
                        v-if="$store.getters.getRole == 1"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </template>
                </b-table>
                <hr/>
                <b-row class="d-flex align-items-center mt-1">
                  <b-col cols="6">
                    <p class="text-muted">Toplam {{ thirdTotalRows }} Kayıt bulundu</p>
                  </b-col>
                  <b-col cols="6">
                    <b-pagination
                        v-model="thirdCurrentPage"
                        @change="thirdCurrentPageChange($event)"
                        :total-rows="thirdTotalRows"
                        :per-page="thirdPerPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0 float-right"
                    />
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BContainer,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
import { useWindowSize } from '@vueuse/core'

flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BForm,
    vSelect,
    BFormInvalidFeedback,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      currentTab: null,
      fields: [
        {
          key: 'ihaleID',
          label: 'ID',
          sortable: true,
          thStyle: {
            width: '3%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '3%' },
        },
        {
          key: 'ihaleDurum',
          label: 'İhale Durum',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'ihaleBaslik',
          label: 'İhale Başlık',
          sortable: true,
          thStyle: {
            width: '30%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '19%' },
        },
        {
          key: 'belgeTarihSayi',
          label: 'Belge Tarih Sayı',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '13%' },
        },
        {
          key: 'belgeTarih',
          label: 'Belge Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'kayitTarih',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      thirdPerPage: 20,
      thirdToplamKayit: 1,
      thirdTotalRows: 1,
      thirdCurrentPage: 1,
      thirdFilter: null,
      secondPerPage: 20,
      secondToplamKayit: 1,
      secondTotalRows: 1,
      secondCurrentPage: 1,
      secondFilter: null,
      firstPerPage: 20,
      firstToplamKayit: 1,
      firstTotalRows: 1,
      firstCurrentPage: 1,
      firstFilter: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
    }
  },
  computed: {
    ihaleListesi() {
      if (store.getters.IHALE_LISTESI_GET) {
        return Object.values(store.getters.IHALE_LISTESI_GET)
      }
      return []
    },
    acikIhaleler() {
      if (store.getters.ACIK_IHALE_GET) {
        return Object.values(store.getters.ACIK_IHALE_GET)
      }
      return []
    },
    kapaliIhaleler() {
      if (store.getters.KAPALI_IHALE_GET) {
        return Object.values(store.getters.KAPALI_IHALE_GET)
      }
      return []
    },
  },
  methods: {
    acikIhale() {
      // if (this.acikIhaleler.length === 0) {
      store.dispatch('acikIhale', {
        searchKey: this.secondFilter,
        uzunluk: this.secondPerPage,
        baslangic: 0,
      })
          .then(res => {
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste?.length
          })
      // }
    },
    kapaliIhale() {
      // if (this.kapaliIhaleler.length === 0) {
      store.dispatch('kapaliIhale', {
        searchKey: this.thirdFilter,
        uzunluk: this.thirdPerPage,
        baslangic: 0,
      })
          .then(res => {
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste?.length
          })
      // }
    },
    ihaleRemove(id) {
      this.$swal({
        title: 'İhaleyi Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('ihaleSil', id)
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status === 200) {
                      if (this.currentTab === 0) {
                        this.acikIhale()
                      } else {
                        this.kapaliIhale()
                      }
                      // store.dispatch('ihaleListesi', {
                      //   searchKey: this.firstFilter,
                      //   uzunluk: this.firstPerPage,
                      //   baslangic: 0,
                      // })
                      //     .then(res => {
                      //       this.firstTotalRows = res.toplamKayit
                      //       this.firstToplamKayit = res.liste.length
                      //     })
                      // this.acikIhaleler.length != 0 ? this.acikIhale() : this.kapaliIhaleler.length != 0 ? this.kapaliIhale() : ''
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı!',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        }, position)
                      }
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      }, position)
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    thirdCurrentPageChange(newVal) {
      const veri = newVal * this.thirdPerPage - this.thirdPerPage
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('kapaliIhale', {
            uzunluk: this.thirdPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste.length
          })
    },
    thirdPerPageChange(newVal) {
      const veri = this.thirdCurrentPage * newVal - newVal
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('kapaliIhale', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.thirdFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste.length
          })
    },
    secondCurrentPageChange(newVal) {
      const veri = newVal * this.secondPerPage - this.secondPerPage
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('acikIhale', {
            uzunluk: this.secondPerPage,
            baslangic: veri,
            searchKey: this.secondFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste.length
          })
    },
    secondPerPageChange(newVal) {
      const veri = this.secondCurrentPage * newVal - newVal
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('acikIhale', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.secondFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste.length
          })
    },
    firstCurrentPageChange(newVal) {
      const veri = newVal * this.firstPerPage - this.firstPerPage
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('ihaleListesi', {
            uzunluk: this.firstPerPage,
            baslangic: veri,
            searchKey: this.firstFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste.length
          })
    },
    firstPerPageChange(newVal) {
      const veri = this.firstCurrentPage * newVal - newVal
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('ihaleListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.firstFilter,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste.length
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.firstTotalRows = filteredItems.length
      this.firstCurrentPage = 1
    },
    go(item) {
      router.push({
        name: 'ihaleDetay',
        params: { id: item.ihaleID },
      })
    },
  },
  beforeDestroy() {
    store.commit('IHALE_LIST_ACTION', 'remove')
    store.commit('ACIK_IHALE_ACTION', 'remove')
    store.commit('KAPALI_IHALE_ACTION', 'remove')
  },
  watch: {
    thirdFilter(newVal) {
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('kapaliIhale', {
            uzunluk: this.thirdPerPage,
            baslangic: 0,
            searchKey: newVal,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.thirdTotalRows = res.toplamKayit
            this.thirdToplamKayit = res.liste.length
          })
    },
    secondFilter(newVal) {
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('acikIhale', {
            uzunluk: this.secondPerPage,
            baslangic: 0,
            searchKey: newVal,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.secondTotalRows = res.toplamKayit
            this.secondToplamKayit = res.liste.length
          })
    },
    firstFilter(newVal) {
      this.$refs['first-card'].showLoading = true
      this.$store
          .dispatch('ihaleListesi', {
            uzunluk: this.firstPerPage,
            baslangic: 0,
            searchKey: newVal,
          })
          .then(res => {
            this.$refs['first-card'].showLoading = false
            this.firstTotalRows = res.toplamKayit
            this.firstToplamKayit = res.liste.length
          })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.acikIhale()
    // store.dispatch('acikIhale', {
    //   searchKey: this.secondFilter,
    //   uzunluk: this.secondPerPage,
    //   baslangic: 0,
    // })
    //     .then(res => {
    //       this.secondTotalRows = res.toplamKayit
    //       this.secondToplamKayit = res.liste?.length
    //     })
  },
}
</script>
<style lang="scss">
.ihale-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.search-input {
  border-color: #c3baba !important;
}

.check-to-slot {
  height: 14px !important;
  width: 20px !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.per-page-input {
  width: 35%;
}

.search-bar {
  width: 30% !important;
  float: right;
}

.ihale-list-top {
  place-items: center;
  place-content: center;
  justify-content: space-between;
  display: flex;
}
</style>
